<template>
  <div>
    <div
      class="text-input"
      :class="{
        'text-input-focus': focus,
        'text-input-with-icon': props.icon,
        'input-error': props.error,
      }"
    >
      <img v-if="props.icon" :src="props.icon" />
      <input
        :value="props.modelValue"
        :type="type"
        @input="handleInput"
        :placeholder="placeholder"
        @focus="handleFocus"
        @blur="handleBlur"
        v-bind="$attrs"
      />
    </div>
    <p
      v-if="errorMessage"
      class="text-sm text-red-500 mt-1"
      :class="{ 'opacity-100': props.error, 'opacity-0': !props.error }"
    >
      {{ props.errorMessage }}
    </p>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    modelValue: string;
    type?: string;
    placeholder?: string;
    icon?: string;
    error?: boolean;
    errorMessage?: string;
  }>(),
  {
    modelValue: "",
    type: "text",
    placeholder: "",
    icon: "",
    errorMessage: "",
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
  (e: "blur", value: FocusEvent): void;
  (e: "focus", value: FocusEvent): void;
  (e: "change"): void;
}>();

const focus = ref(false);

function handleInput(event: Event): void {
  const target = <HTMLInputElement>event.target;
  emit("update:modelValue", target.value);
  emit("change");
  return;
}

function handleFocus(event: FocusEvent): void {
  focus.value = true;
  emit("focus", event);
  return;
}

function handleBlur(event: FocusEvent): void {
  focus.value = false;
  emit("blur", event);
  return;
}
</script>

<style lang="scss">
.text-input {
  @apply flex items-center gap-2 px-3 md:px-4 py-2 w-full text-sm md:text-base border border-gray-200 bg-white rounded-lg;

  img {
    @apply w-5 h-5;
    // #9DA3AE
    filter: invert(75%) sepia(8%) saturate(372%) hue-rotate(180deg)
      brightness(86%) contrast(88%);
  }

  input {
    @apply w-full focus:outline-none;
  }

  &.text-input-with-icon {
    @apply pl-3 pr-5;
  }

  &.text-input-focus {
    @apply border-brand-blue;

    img {
      // Color black
      filter: invert(0%) sepia(0%) saturate(7469%) hue-rotate(217deg)
        brightness(108%) contrast(108%);
    }
  }

  &.input-error {
    @apply border-red-500;
  }
}
</style>
